import {
  Box,
  TextField,
  Typography,
  styled,
  TextFieldProps,
  CircularProgress,
  FormLabel,
  FormControl,
  useTheme,
} from "@mui/material";
import {
  AdminDetailUserInfoResponse,
  UserTypeResponse,
  CareTeamMemberInfo,
  AccountStatus,
  TechRoleEnum,
} from "@veris-health/user-ms/lib/v1";
import React, { useEffect, useState, useMemo } from "react";
import { intersectionBy, xorBy } from "lodash";
import { VrsButton, VrsConfirmationModals, VrsSelect } from "@veris-health/web-core";
import { hospitalsApiV1, teamsApiV1 } from "../../../api";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Status } from "../../shared/interfaces";
import { setPatientHospital, setPatientMainOncologist } from "../api/hospitalsApi";
import { getUsers } from "../api/usersApi";
import { selectHospitals, setShouldUpdateUser } from "../userSlice";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import CareTeamMembersPicker from "./CareTeamMembersPicker";
import { extractErrorMessage } from "../../shared/helpers";
import { useAuthenticatedUserRoles } from "../../../context/profile";

const removeMembersFromCareTeam = async (
  members: Array<CareTeamMemberInfo | AdminDetailUserInfoResponse>,
  userId: number,
) => {
  await Promise.all(
    members.map((member) =>
      teamsApiV1.deleteCareTeamMember(userId, { member_id: member.id, patient_id: userId }),
    ),
  );
};

const addMembersToCareTeam = async (
  userId: number,
  members: Array<CareTeamMemberInfo | AdminDetailUserInfoResponse>,
) => {
  await Promise.all(
    members.map((member) =>
      teamsApiV1.addCareTeamMember(userId, { member_id: member.id, patient_id: userId }),
    ),
  );
};

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  paddingTop: "3px",
  ".MuiInput-root:before, .MuiInput-root:after, .MuiInput-root:hover, .MuiInput-root:hover:not(.Mui-disabled):before":
    {
      border: "none !important",
    },
  ".MuiInput-root.MuiInputBase-sizeSmall": {
    ".MuiInput-input": {
      ...theme.typography.body,
      height: "20px",
      paddingBottom: 0,
      [theme.breakpoints.down("lg")]: {
        padding: theme.spacing(0.725, 0.58, 0, 0),
      },
    },
  },
}));
export interface SetHospitalAndCareTeamFormProps {
  setToggleForm: () => void;
  user: AdminDetailUserInfoResponse;
  existingCareTeamMembers?: CareTeamMemberInfo[];
  hospitalEdit: boolean;
}

const SetHospitalAndCareTeamForm = ({
  setToggleForm,
  user,
  existingCareTeamMembers,
  hospitalEdit,
}: SetHospitalAndCareTeamFormProps): JSX.Element => {
  const hospitals = useAppSelector(selectHospitals);
  const [hospital, setHospital] = useState<string>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedCareTeam, setSelectedCareTeam] = React.useState<
    { value: AdminDetailUserInfoResponse | CareTeamMemberInfo; label: string | undefined }[]
  >([]);
  const [careTeamMembers, setCareTeamMembers] = useState<AdminDetailUserInfoResponse[]>([]);
  const [careTeamMembersStatus, setCareTeamMembersStatus] = useState<Status>("idle");
  const [status, setStatus] = useState<Status>("idle");
  const [mainOncologistId, setMainOncologistId] = useState<string>("");
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const currentMainOncologist = useMemo(
    () => existingCareTeamMembers?.find((member) => member.is_main_oncologist),
    [existingCareTeamMembers],
  );

  const mainOncologistOptions = selectedCareTeam
    .filter((member) => {
      const { value } = member;
      const roles = (value as CareTeamMemberInfo).roles || [];
      const userRoles = (value as AdminDetailUserInfoResponse)?.user_roles || [];
      return roles.includes("doctor") || userRoles.some((role) => role.role_name === "doctor");
    })
    .map(({ label, value }) => ({
      label: label ?? "",
      value: value?.id?.toString() ?? "",
    }));

  const handleChange = (
    value: { value: AdminDetailUserInfoResponse | CareTeamMemberInfo; label: string | undefined }[],
  ) => {
    setSelectedCareTeam(value);
    const mainOncologistInHospital = value.find((member) => member.value.id === +mainOncologistId);
    if (!mainOncologistInHospital) {
      setMainOncologistId("");
    }
  };

  const fetchHospitalUsers = (hospitalId?: number, reset?: boolean) => {
    getUsers({
      type: [UserTypeResponse.MedStaff],
      hospital: Number(hospitalId || hospital),
      accountStatus: AccountStatus.Active,
    }).then((response) => {
      setCareTeamMembers(reset ? response.items : [...careTeamMembers, ...response.items]);
      setCareTeamMembersStatus("idle");
    });
  };

  const onHospitalChange = (e: string) => {
    setCareTeamMembersStatus("loading");
    setHospital(e);
    setSelectedCareTeam([]);
    setMainOncologistId("");
    fetchHospitalUsers(+e, true);
  };
  const authenticatedUserRoles = useAuthenticatedUserRoles();

  const canCreateHospital = [TechRoleEnum.Admin, TechRoleEnum.Superadmin].some((item) =>
    authenticatedUserRoles.includes(item),
  );

  const handleSubmit = async () => {
    if (user && hospital) {
      const members = selectedCareTeam.map((entry) => entry.value);
      if (!existingCareTeamMembers?.length) {
        await handleCreate(+hospital, members);
      } else if (user.hospital?.length) {
        const originalHospital = user.hospital[0].id;
        await handleEdit(existingCareTeamMembers, members, originalHospital, +hospital);
      }
    }
  };

  async function handleCreate(
    hospitalId: number,
    careMembers: Array<CareTeamMemberInfo | AdminDetailUserInfoResponse>,
  ) {
    setStatus("loading");

    try {
      if (canCreateHospital) {
        await setPatientHospital(user.id, hospitalId);
        SnackbarUtils.success("Hospital successfuly added.");
      }

      await addMembersToCareTeam(user.id, careMembers);
      SnackbarUtils.success("Care team successfuly saved.");

      await setPatientMainOncologist(user.id, { med_staff_id: +mainOncologistId });
      SnackbarUtils.success("Main oncologist successfuly saved.");

      dispatch(setShouldUpdateUser(true));
      setToggleForm();
      setStatus("idle");
    } catch (err) {
      const errorMsg = extractErrorMessage(err);
      SnackbarUtils.error(errorMsg || "Could not add hospital and team members, please try again.");
      setStatus("idle");
    }
  }

  async function handleEdit(
    oldMembers: Array<CareTeamMemberInfo | AdminDetailUserInfoResponse>,
    newMembers: Array<CareTeamMemberInfo | AdminDetailUserInfoResponse>,
    oldHospitalId: number,
    newHospitalId: number,
  ) {
    if (oldHospitalId !== newHospitalId) {
      setShowConfirmation(true);
    } else {
      /** The below ops are due to the way the API requires re-assignment of team members
       * We have to find the unchanged members first to avoid unnecessary requests.
       * */
      const unchangedMembers = intersectionBy(oldMembers, newMembers, (member) => member.id);

      /**
       * Comparing the unchangedMembers array derived above with the members arriving from the autocomplete to extract only the new members,
       * and prevent sending API requests for the unchanged members in the meantime.
       */
      const membersToBeAdded = xorBy(unchangedMembers, newMembers, (member) => member.id);

      /**
       * Similarly to the op above, we have to extract only the members that have to be removed,
       * without sending additional requests for the ones that need to stay.
       * */
      const membersToBeRemoved = xorBy(unchangedMembers, oldMembers, (member) => member.id);

      try {
        if (membersToBeAdded.length || membersToBeRemoved.length) {
          await Promise.all([
            removeMembersFromCareTeam(membersToBeRemoved, user.id),
            addMembersToCareTeam(user.id, membersToBeAdded),
          ]);
          SnackbarUtils.success("Care team successfuly saved.");
        }
        if (+mainOncologistId !== currentMainOncologist?.id) {
          await setPatientMainOncologist(user.id, { med_staff_id: +mainOncologistId });
          SnackbarUtils.success("Main oncologist successfuly saved.");
        }
        dispatch(setShouldUpdateUser(true));
        setToggleForm();
        setStatus("idle");
      } catch (err) {
        const errorMsg = extractErrorMessage(err);
        SnackbarUtils.error(
          errorMsg || "Could not add hospital and team members, please try again.",
        );
        setStatus("idle");
      }
    }
  }

  const onHospitalEdit = async () => {
    setShowConfirmation(false);
    if (user && hospital && user.hospital?.length) {
      if (existingCareTeamMembers) {
        await removeMembersFromCareTeam(existingCareTeamMembers, user.id);
      }
      await hospitalsApiV1.unsetPatientHospital(user.id, { hospital_id: user.hospital[0].id });
      await handleCreate(
        +hospital,
        selectedCareTeam.map((entry) => entry.value),
      );
    }
  };

  useEffect(() => {
    if (existingCareTeamMembers && user?.hospital?.length) {
      onHospitalChange(user.hospital[0].id.toString());

      setSelectedCareTeam(
        existingCareTeamMembers.map((member) => ({
          label: member.full_name,
          value: member,
        })),
      );

      const currentMainOncologistId = currentMainOncologist ? currentMainOncologist.id : "";
      setMainOncologistId(`${currentMainOncologistId}`);
    }
  }, [existingCareTeamMembers, user]);

  return (
    <Box
      my={2}
      px={2}
      py={2}
      sx={{
        backgroundColor: theme.veris.colors.neutrals.white,
        border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: theme.spacing(1),
      }}
    >
      <Typography variant="h5" mb={2}>
        Set {hospitalEdit && "Hospital and "} Clinical Care Team
      </Typography>
      {hospitalEdit && (
        <VrsSelect
          innerSx={{ maxWidth: "max-content", paddingRight: theme.spacing(1) }}
          value={hospital}
          options={hospitals.map((item) => {
            return {
              label: item.name,
              value: String(item.id),
            };
          })}
          placeholder="Choose hospital"
          onSelected={onHospitalChange}
          maxHeight="250px"
        />
      )}
      {hospital && (
        <CareTeamMembersPicker
          handleChange={handleChange}
          careTeamMembersStatus={careTeamMembersStatus}
          selectedCareTeam={selectedCareTeam}
          careTeamMembers={careTeamMembers}
        />
      )}
      {hospital && (
        <FormControl required>
          <Typography m={0} variant="subtitle2">
            Set main oncologist
            <FormLabel />
          </Typography>
          <VrsSelect
            required
            disabled={selectedCareTeam.length === 0}
            innerSx={{
              maxWidth: "max-content",
              paddingRight: theme.spacing(1),
            }}
            value={mainOncologistId}
            options={mainOncologistOptions}
            placeholder={
              selectedCareTeam.length === 0
                ? "Select Clinical Care Team member(s)"
                : "Choose main oncologist"
            }
            onSelected={setMainOncologistId}
            maxHeight="250px"
          />
        </FormControl>
      )}
      <Box display="flex" justifyContent="end" gap={2} py={2}>
        <VrsButton
          buttonType="tertiary"
          onClick={handleSubmit}
          disabled={status === "loading" || !hospital || !mainOncologistId}
        >
          Save
          {status === "loading" && (
            <CircularProgress sx={{ marginLeft: theme.spacing(2) }} size={16} />
          )}
        </VrsButton>
        <VrsButton buttonType="secondary" onClick={setToggleForm}>
          Cancel
        </VrsButton>
      </Box>
      <VrsConfirmationModals
        isOpen={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        dialogHeader="Warning!"
        dialogContent="When switching hospitals, the entire previous clinical care team will be removed from the patient!"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onCancel={() => setShowConfirmation(false)}
        onConfirm={() => onHospitalEdit()}
      />
    </Box>
  );
};

export default SetHospitalAndCareTeamForm;
