import React, { useMemo, useState } from "react";
import dayjs from "dayjs";
import { Typography, Box, Grid, FormLabel, useTheme, FormControl, Checkbox } from "@mui/material";
import * as yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { CodeType, CreatePatientRequest } from "@veris-health/user-ms/lib/v1";
import { VrsButton, VrsSelect, Option, VrsInfo, dateFormats } from "@veris-health/web-core";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectHospitals } from "../../Users/userSlice";
import { VrsFormInputField } from "../../../ui/components/VrsFormInputField";
import { PhoneFormInputField } from "../../LoginRegistration/PhoneFormInputField";
import { VrsDateInputField } from "../../../ui/components/VrsDateField";

const MINIMUM_AGE = 18;

const validationSchema = yup.object({
  name: yup.string().required("Please enter Last Name, First Name").min(1),
  hospital: yup.number().required("Hospital is required."),
  ehrId: yup.string().required("EHR ID is required."),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .max(20)
    .when("country", {
      is: (value: string) => value.length >= 2,
      then: yup.string().min(8),
      otherwise: yup.string().min(10),
    })
    .matches(/^[+]?\d+$/, "Should contain only digits"),
  dateOfBirth: yup.lazy(() =>
    yup
      .date()
      .required("Date of birth is required.")
      .typeError("The value must be a date")
      .max(dayjs(), "Invalid date")
      .test("dateOfBirth", "Should be greater than 17", (value, { createError }) => {
        const dob: Date | number = new Date(value || 0);
        const isValid = dayjs().diff(dob, "year") >= MINIMUM_AGE;
        return !isValid
          ? createError({ message: `The patient must be ${MINIMUM_AGE} years or older` })
          : isValid;
      }),
  ),
  codeType: yup.mixed<CodeType>().oneOf(Object.values(CodeType)).required("Code type is required."),
  code: yup.string().when("codeType", {
    is: CodeType.ImplantSerialNumber,
    then: yup.string().required("Code must be entered if Code Type is Implant"),
  }),
  cancerType: yup.string(),
  cancerStage: yup.string(),
  icd10Code: yup.string().required("ICD10 Code is required"),
});

const initialValues = {
  name: "",
  hospital: undefined,
  ehrId: "",
  phoneNumber: "",
  dateOfBirth: dayjs(),
  cancerType: "",
  cancerStage: "",
  codeType: CodeType.MioHubSerialNumber,
  code: "",
  country: "1",
  icd10Code: "",
  testUser: false,
};

const codeTypeOptions: Option[] = [
  {
    label: "Implant",
    value: CodeType.ImplantSerialNumber,
  },
  {
    label: "At-home devices",
    value: CodeType.MioHubSerialNumber,
  },
];

type InvitePatientProps = {
  handlePatientCreated: (payload: CreatePatientRequest) => void;
};

export const InvitePatient = ({ handlePatientCreated }: InvitePatientProps): JSX.Element => {
  const hospitals = useAppSelector(selectHospitals);
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      setLoading(true);
      const payload: CreatePatientRequest = {
        full_name: values.name,
        code:
          (values.codeType as CodeType) === CodeType.ImplantSerialNumber ? values.code : undefined,
        phone_number: `+${values.country}${values.phoneNumber}`,
        dob: values.dateOfBirth.format(dateFormats["YYYY-MM-DD"]),
        cancer_stage: values.cancerStage ? values.cancerStage : undefined,
        hospital_id: Number(values.hospital),
        cancer_type: values.cancerType ? values.cancerType : undefined,
        ehr_id: values.ehrId,
        code_type: values.codeType,
        icd10_code: values.icd10Code,
        test_user: values.testUser,
        care_team_member_ids: [],
      };
      handlePatientCreated(payload);
    },
  });

  const {
    codeType,
    code,
    name,
    cancerType,
    cancerStage,
    ehrId,
    dateOfBirth,
    hospital,
    phoneNumber,
    icd10Code,
    testUser,
  } = formik.values;

  const {
    errors,
    handleSubmit,
    isValid,
    handleChange,
    touched,
    setFieldValue,
    dirty,
    handleBlur,
    setFieldTouched,
  } = formik;

  const hospitalOptions: Option[] = useMemo(() => {
    return hospitals.map((item) => ({ label: item.name, value: item.id.toString() }));
  }, [hospitals]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Grid item xs={12} container spacing={4} style={{ margin: "auto", width: "auto" }}>
          <Grid item xs={6}>
            <VrsFormInputField
              name="name"
              label="Full name"
              placeholder="Last Name, First Name"
              helperText={touched.name && errors.name ? errors.name : " "}
              error={!!touched.name && Boolean(errors.name)}
              isValidField={name.length && !errors.name}
              value={name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <VrsFormInputField
              name="ehrId"
              label="EHR ID"
              helperText={touched.ehrId && errors.ehrId ? errors.ehrId : " "}
              error={!!touched.ehrId && Boolean(errors.ehrId)}
              isValidField={ehrId.length && !errors.ehrId}
              value={ehrId}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle2">
                Hospital
                <FormLabel required />
              </Typography>
              <VrsSelect
                innerSx={{ padding: theme.spacing(0.75, 0.75, 0.75, 0) }}
                value={hospital}
                options={hospitalOptions}
                placeholder="Choose hospital"
                onSelected={(value) => setFieldValue("hospital", value)}
                onBlur={() => setFieldTouched("hospital", true)}
                maxHeight="250px"
              />
              {touched.hospital && errors.hospital && (
                <Grid item xs={12} style={{ paddingLeft: 0 }}>
                  <Typography
                    color={theme.veris.colors.errors.normal}
                    variant="caption"
                    component="div"
                    my={1.5}
                  >
                    {errors.hospital}
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <VrsDateInputField
              name="dateOfBirth"
              label="Date of Birth"
              maxDate={dayjs()}
              value={dateOfBirth}
              helperText={
                touched.dateOfBirth && errors.dateOfBirth ? (errors.dateOfBirth as string) : " "
              }
              error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
              onChange={(field, value) => {
                setFieldTouched(field, true);
                setFieldValue(field, value);
              }}
              onBlur={handleBlur}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <VrsFormInputField
              name="icd10Code"
              label="ICD10 Code"
              helperText={touched.icd10Code && errors.icd10Code ? errors.icd10Code : " "}
              error={!!touched.icd10Code && Boolean(errors.icd10Code)}
              isValidField={icd10Code?.length && !errors.icd10Code}
              value={icd10Code}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <VrsFormInputField
              name="cancerType"
              label="Cancer Type"
              helperText={touched.cancerType && errors.cancerType ? errors.cancerType : " "}
              error={!!touched.cancerType && Boolean(errors.cancerType)}
              isValidField={cancerType?.length && !errors.cancerType}
              value={cancerType}
              required={false}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <VrsFormInputField
              name="cancerStage"
              label="Cancer Stage"
              helperText={touched.cancerStage && errors.cancerStage ? errors.cancerStage : " "}
              error={!!touched.cancerStage && Boolean(errors.cancerStage)}
              isValidField={cancerStage?.length && !errors.cancerStage}
              value={cancerStage}
              onChange={handleChange}
              required={false}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneFormInputField
              name="phoneNumber"
              label="Phone Number"
              value={phoneNumber}
              onChange={handleChange}
              helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : " "}
              onBlur={handleBlur}
              error={Boolean(errors.phoneNumber) && !!touched.phoneNumber}
              isValidField={phoneNumber.length && !errors.phoneNumber}
              onSelected={(value) => {
                setFieldValue("country", value.code.trim());
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography mb={1} variant="subtitle2">
                Registration flow
              </Typography>
              <FormLabel />
              <VrsSelect
                innerSx={{ padding: theme.spacing(0.75, 0.75, 0.75, 0) }}
                value={codeType}
                options={codeTypeOptions}
                onSelected={(value) => setFieldValue("codeType", value)}
                disabled
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            {(codeType as CodeType) === CodeType.ImplantSerialNumber && (
              <VrsFormInputField
                name="code"
                label="Full name"
                placeholder="Last and First name"
                helperText={touched.code && errors.code ? errors.code : " "}
                error={!!touched.code && Boolean(errors.code)}
                isValidField={code?.length && !errors.code}
                value={code}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl
              required
              sx={{
                display: "flex",
                flexDirection: "initial",
                alignItems: "center",
                left: ({ spacing }) => spacing(-1.75),
                height: ({ spacing }) => spacing(3),
              }}
            >
              <Field
                name="testUser"
                type="checkbox"
                size="large"
                variant="outlined"
                as={Checkbox}
                value={testUser}
                onChange={handleChange}
                checked={testUser}
                style={{ color: theme.veris.colors.amethyst.normal }}
              />
              <Box height="1rem" display="flex">
                <Typography m={0} variant="caption" display="flex" alignItems="center">
                  Test Patient
                </Typography>
                <VrsInfo
                  description="The Test Patient role is permanent. Once assigned, it cannot be reverted to a regular role."
                  size={15}
                />
              </Box>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            marginTop={4}
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <VrsButton
              buttonType="primary"
              disabled={!isValid || !dirty || loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              Next
            </VrsButton>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
};
