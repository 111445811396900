import React, { useEffect, useState } from "react";
import { Box, DialogContent, Grid, Typography, useTheme } from "@mui/material";
import {
  AccountStatus,
  AdminDetailUserInfoResponse,
  CareTeamMemberInfo,
  UserTypeResponse,
  CreatePatientRequest,
} from "@veris-health/user-ms/lib/v1";
import { IconName, VrsButton, VrsDialog, VrsIcon } from "@veris-health/web-core";
import { InvitePatient } from "../components/InvitePatient";
import { getUsers } from "../../Users/api/usersApi";
import { Status } from "../../shared/interfaces";
import CareTeamMembersPicker from "../../Users/components/CareTeamMembersPicker";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { InvitePatientMainOncologist } from "../components/InvitePatientMainOncologist";
import { invitePatient } from "../api/actionsApi";

interface CreatePatientPayload {
  patientData: CreatePatientRequest;
  careTeamMembers: Array<AdminDetailUserInfoResponse | CareTeamMemberInfo>;
  mainOncologistId: string;
}

interface CareTeamMember {
  value: AdminDetailUserInfoResponse | CareTeamMemberInfo;
  label: string | undefined;
}

const InviteUserContainer = (): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const [open, setOpen] = useState(false);
  const [networkStatus, setNetworkStatus] = useState<Status>("idle");
  const [formData, setFormData] = useState<Partial<CreatePatientPayload>>({});
  const [careTeamMembers, setCareTeamMembers] = useState<AdminDetailUserInfoResponse[]>([]);
  const [selectedCareTeam, setSelectedCareTeam] = useState<CareTeamMember[]>([]);

  const theme = useTheme();

  useEffect(
    () => () => {
      setTimeout(() => {
        setStep(1);
        setFormData({});
        setSelectedCareTeam([]);
        setCareTeamMembers([]);
      }, 0);
    },
    [open],
  );

  const fetchHospitalUsers = (hospitalId: number) => {
    setNetworkStatus("loading");
    getUsers({
      type: [UserTypeResponse.MedStaff],
      hospital: hospitalId,
      accountStatus: AccountStatus.Active,
    })
      .then((response) => {
        setCareTeamMembers(response.items);
        setNetworkStatus("idle");
      })
      .catch(() => {
        setNetworkStatus("failed");
        SnackbarUtils.error("Something went wrong while fetching hospital members.");
      });
  };

  const handlePatientCreated = (payload: CreatePatientRequest) => {
    setFormData((prev) => ({
      ...prev,
      patientData: { ...payload },
    }));
    fetchHospitalUsers(payload.hospital_id);
    setStep(2);
  };

  const handleCareTeamSelected = () => {
    setFormData((prev) => ({
      ...prev,
      careTeamMembers: selectedCareTeam.map((entry) => entry.value),
    }));
    setStep(3);
  };

  const handleSubmitAllData = async (mainOncologistId: string) => {
    try {
      setNetworkStatus("loading");

      await invitePatient({
        ...formData.patientData,
        main_oncologist_id: Number(mainOncologistId),
        care_team_member_ids: formData.careTeamMembers?.map((i) => i.id) || [],
      } as CreatePatientRequest);

      SnackbarUtils.success("Patient setup completed successfully");
      setOpen(false);
    } catch (err) {
      SnackbarUtils.error("Failed to complete patient setup");
    } finally {
      setNetworkStatus("idle");
    }
  };

  let title;
  let currentForm;
  if (step === 1) {
    title = "Create Patient (1)";
    currentForm = <InvitePatient handlePatientCreated={handlePatientCreated} />;
  } else if (step === 2) {
    title = "Select Clinical Care Team Members (2)";
    currentForm = (
      <>
        <CareTeamMembersPicker
          careTeamMembers={careTeamMembers}
          careTeamMembersStatus={networkStatus}
          handleChange={setSelectedCareTeam}
          selectedCareTeam={selectedCareTeam}
        />
        <Grid item xs={12} marginTop={4} style={{ display: "flex", flexDirection: "row-reverse" }}>
          <VrsButton
            buttonType="primary"
            disabled={selectedCareTeam.length === 0 || networkStatus === "loading"}
            variant="contained"
            color="primary"
            onClick={handleCareTeamSelected}
          >
            Next
          </VrsButton>
        </Grid>
      </>
    );
  } else if (step === 3) {
    title = "Select Main Oncologist (3)";
    currentForm = (
      <InvitePatientMainOncologist
        selectedCareTeam={selectedCareTeam}
        setMainOncologist={handleSubmitAllData}
        status={networkStatus}
      />
    );
  }

  return (
    <Box pb={2} display="flex" justifyContent="flex-start">
      <VrsButton buttonType="primary" size="small" onClick={() => setOpen(true)}>
        <Typography pl={3} variant="bodySemibold" fontWeight={400} pt={0.25}>
          Create Patient
        </Typography>
        <Box pr={3} pl={1} pt={0.75}>
          <VrsIcon name={IconName.Plus} size={18} stroke={theme.veris.colors.neutrals.white} />
        </Box>
      </VrsButton>
      <VrsDialog
        open={open}
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setOpen(false);
          }
        }}
        title={title}
        sx={{ "& .MuiPaper-root": { height: "auto", minWidth: "600px" } }}
      >
        <DialogContent sx={{ overflowY: "unset" }}>{currentForm}</DialogContent>
      </VrsDialog>
    </Box>
  );
};

export default InviteUserContainer;
